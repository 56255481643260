import axios from "@/axios/axios.js";
//赠金点
export default {
  //个人赠金点明细列表
  async grantsPointDetail(params) {
    return await axios.post('/uc/userGrantsPoint/grantsPointDetail', params, { des: '个人赠金点明细列表', sTime: new Date().getTime() })
  },
  //个人赠金点页面
  async userGrantsPoint(params) {
    return await axios.post('/uc/userGrantsPoint/userGrantsPoint', params, { des: '个人赠金点页面', sTime: new Date().getTime() })
  },
  //赠金点兑现
  async cash(params) {
    return await axios.post('/uc/userGrantsPoint/cash', params, { des: '赠金点兑现', sTime: new Date().getTime() })
  },
  //个人兑现明细列表
  async cashDetail(params) {
    return await axios.post('/uc/userGrantsPoint/cashDetail', params, { des: '个人兑现明细列表', sTime: new Date().getTime() })
  },
  //赠金点规则
  async cashPointConfig(params) {
    return await axios.post('/uc/userGrantsPoint/cashPointConfig', params, { des: '赠金点规则', sTime: new Date().getTime() })
  },
}