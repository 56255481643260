<template>
  <div class="con_top">
    <div class="bonus">
      <div class="title">
        <div class="lefts"><div class="doller">美元赠金</div><span>本月交易量{{dollarGiftInfo.convertRate}}手，当前每交易1手可兑换${{dollarGiftInfo.convertRate}}</span></div>
        <div class="rights" @click="popupShow=true">赠金规则</div>
      </div>
      <div class="detialnum">
        <div class="detialbody">
          <div class="available"><span class="num_b">${{dollarGiftInfo.sumPoint}}</span><span class="num_c">可用赠金</span></div>
          <div class="total"><span class="num_b">${{dollarGiftInfo.sumCashMoney}}</span><span class="num_c">累计已兑现</span></div>
          <div class="current"><span class="num_b">${{dollarGiftInfo.cashMoney}}</span><span class="num_c">当前可兑现</span></div>
        </div>
      </div>
      <div class="buttom_exchange">
        <button class="buttom_curr" :disabled="!dollarGiftInfo.isCash" @click="submitExchange"><span class="immediately">立即兑现</span><span class="enough">今天还可以兑现 <font color="red" v-text="dollarGiftInfo.isCash?1:0"></font> 次</span></button>
      </div>
    </div>
    <div class="bonuslist">
      <div class="bodd">
        <div class="dollar-list">
          <van-tabs v-model="dollarTab">
            <van-tab title="赠金明细">
              <detailList :isReload="dollarGiftInfo.isCash"></detailList>
            </van-tab>
            <van-tab title="兑现记录">
              <recordList :isReload="dollarGiftInfo.isCash"></recordList>
            </van-tab>
          </van-tabs>
        </div>
      </div>
    </div>
    <van-popup closeable close-icon="close" :close-on-click-overlay="false" v-model="popupShow" class="dollar-modal-rules">
      <div class="dollar-modal-box">
        <div class="dollar-modal-header">
          <div class="dollar-modal-title">赠金规则</div>
          <div class="dollar-modal-close" @click="dollarModalClose"></div>
        </div>
        <div class="dollar-modal-body" v-html="cashPointConfig"></div>
      </div>
    </van-popup>
  </div>
</template>
<script>
import detailList from '@/components/detailList.vue';
import recordList from '@/components/recordList.vue';
import dollarGift from "@/apis/dollarGift.js";
export default {
  name: "trade",
  components: {
    detailList,
    recordList,
  },
  data() {
    return {
      tabList: [
        {
          label: "赠金明细",
          value: "1",
        },
        {
          label: "兑现记录",
          value: "2",
        }
      ],
      tabIndex: 1, //选项卡默认值
      dollarGiftInfo: {},
      submitExchangeLoading: false,
      popupShow: false,
      cashPointConfig: '',
      dollarTab: 0,
    }
  },
  methods: {
    dollarModalClose() {
      this.popupShow = false;
    },
    // 订单类型切换
    handleChangeTab(index) {
      this.tabIndex = index;
      this.holdOrderId = "";
      this.holdOrderVolum = "";
      this.hungOrderId = "";
      this.isShowRight = false;
    },
     //页面顶部详情
      userGrantsPoint() {
        let loading = this.$toast.loading({
          duration: 0,
          mask: true,
          message: '加载中...'
        });
        dollarGift.userGrantsPoint().then(res => {
          if (res.IsSuccess) {
            this.dollarGiftInfo = Object.assign({}, this.dollarGiftInfo, res.Data);
            loading.clear();
          }
       })
        //赠金点规则
        dollarGift.cashPointConfig().then(res => {
          if (res.IsSuccess) {
            this.cashPointConfig = res.Data[0].rule
          }
        })
      },
      //立即兑换
      submitExchange() {
        this.submitExchangeLoading = true;
        dollarGift.cash({
          money: this.dollarGiftInfo.cashMoney
        }).then(res => {
          if (res.IsSuccess) {
            this.$toast.success('金额兑换成功！');
            this.dollarGiftInfo.cashMoney = 0;
            this.dollarGiftInfo.isCash = false;
          } else {
            this.$toast.fail('金额兑换失败！' + res.Data);
          }
          this.submitExchangeLoading = false;
        })
      },
  },
  mounted() {
    this.userGrantsPoint()
  }
}
</script>
<style lang="scss">
@import "@/assets/scss/funds/bonus.scss";
</style>