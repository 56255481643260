<template>
  <div class="table-list">
    <div class="table-list-header">
      <div>兑现时间</div>
      <div>兑换金额</div>
      <div>状态</div>
    </div>
    <div class="table-list-body">
      <van-list v-model="loading" :offset="30" :finished="finished" @load="cashDetail">
        <div v-for="(item,index) in list" :class="tableListRow(item)" :key="index">
          <div>{{item.cashDate | dateFormat('yyyy-MM-dd hh:mm:ss')}}</div>
          <div>{{item.amount}}</div>
          <div class="state-yellow" v-if="item.status==1">待审核</div>
          <div v-else-if='item.status==2'>已发放</div>
          <div v-else-if='item.status==3'>审核不通过</div>
        </div>
        <div v-if="noData" class="no-data">
          <p>没有任何记录耶~</p>
        </div>
        <div v-else-if="finished" class="no-more">
          暂无更多
        </div>
      </van-list>
    </div>
  </div>
</template>

<script>
  import dollarGift from '@/apis/dollarGift.js';
  export default {
    name: '',
    props: {
      isReload: {
        type: Boolean
      }
    },
    data() {
      return {
        list: [],
        pageSize: 10,
        pageNo: 1,
        pageCount: 0,
        loading: false,
        finished: false,
        noData: false
      }
    },
    filters: {
      dateFormat(d, fmt) {
        if (typeof(d) == "string") {
          d = d.replace(/-/g, '/')
        }
        let date = new Date(d);
        if (!d) {
          return d
        }
        var o = {
          "M+": date.getMonth() + 1, //月份
          "d+": date.getDate(), //日
          "h+": date.getHours(), //小时
          "m+": date.getMinutes(), //分
          "s+": date.getSeconds(), //秒
          "q+": Math.floor((date.getMonth() + 3) / 3), //季度
          "S": date.getMilliseconds() //毫秒
        };
        if (/(y+)/.test(fmt))
          fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
        for (var k in o)
          if (new RegExp("(" + k + ")").test(fmt))
            fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
        return fmt;
      }
    },
    methods: {
      tableListRow(row) {
        let _class = 'table-list-row';
        if (row.status == 2) {
          _class += ' state-grey';
        } else if (row.status == 3) {
          _class += ' state-red';
        }
        return _class
      },
      cashDetail() {
        this.loading = true;
        dollarGift.cashDetail({ pageSize: this.pageSize, pageNo: this.pageNo }).then(res => {
          if (res.IsSuccess) {
            let list = res.Data.list;
            this.pageCount = res.Data.count;
            this.pageNo += 1
            if (!!list && list.length > 0) {
              this.list = list.concat(this.list);
              this.finished = false;
            }
            if (this.list.length >= this.pageCount) {
              this.finished = true;
            }
          }
          if (this.list.length == 0) {
            this.noData = true;
          }
          this.loading = false
        })
      }
    },
    watch: {
      isReload(v, o) {
        if (o === true && v === false) {
          this.list = [];
          this.pageNo = 1;
          this.cashDetail();
        }
      }
    }
  }
</script>

<style lang="scss">
  .table-list {
    .table-list-header {
      font-size: 0.26rem;
      height: 0.8rem;
      line-height: 0.8rem;
      color: #666666;
      background-color: #f8f8f8;
      display: flex;
      flex-wrap: nowrap;
      align-items: flex-start;
      padding: 0 0.3rem;
      font-weight: bold;

      >div {
        width: 29%;
        text-align: center;
      }

      >div:nth-child(1) {
        width: 42%;
      }
    }

    .table-list-body {
      background-color: #ffffff;

      .table-list-row {
        font-size: 0.24rem;
        color: #666666;
        // height: 0.8rem;
        display: flex;
        flex-wrap: nowrap;
        align-items: flex-start;
        border-bottom: 1px solid #f1f1f1;
        margin: 0 0.3rem;

        >div {
          width: 29%;
          text-align: center;
          padding: 0.1rem 0.02rem;
        }

        >div:nth-child(1) {
          width: 42%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      .state-grey {
        >div {
          color: #999999;
        }
      }

      .state-yellow {
        color: #bd8925;
      }
    }

    .no-more {
      text-align: center;
      margin-top: .12rem;
      color: #999;
      font-size: .24rem;
      height: 0.8rem;
      line-height: 0.5rem;
    }

    .no-data {
      text-align: center;
      height:65px;
      line-height: 65px;
      font-size: 16px;
      p {
        height:65px;
        line-height: 65px;
        color:#999999;
      }
    }
  }
</style>